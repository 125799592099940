<template>
  <div>
    <el-table
      :data="tableData"
      row-key="ID"
      :header-cell-style="{background:'#f0f0f0',color:'#666'}"
      @selection-change="selectRole"
      v-loading="loading"
    >
      <el-table-column
        type="selection"
        :reserve-selection="true"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column align="center" prop="sindex" label="序号" width="50"></el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  data () {
    return {
      loading: false,
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      tableData: [], //表格数据
      roleSelectionList: [],
    }
  },
  methods: {
    selectRole(val) {
      this.roleSelectionList = val;
    },
  },
}
</script>
<style lang="scss" scoped>

</style>